<template>
  <div
    class="min-h-screen"
    :class="
      mode === 'dark'
        ? 'bg-dark-80 opacity-100 is-dark text-white-100'
        : 'bg-gray-20 bg-opacity-50 text-black-100'
    "
  >
    <div
      ref="sidebar"
      class="fixed top-0 left-0 z-[998] h-full px-2 duration-300 transition-all origin-left transform border-r without-scrollbar custom:top-[56px]"
      :class="[
        isOpen === true
          ? 'w-70 overflow-visible'
          : ' w-60 overflow-x-hidden overflow-y-auto',
        mode === 'dark' ? 'bg-dark-90 border-dark-70' : 'bg-white-100',
        isCollapsed ? 'custom:left-0' : 'custom:-left-full',
      ]"
      v-if="
        isLoggedIn &&
        $route.name !== 'signup' &&
        $route.name !== 'signup2' &&
        $route.name !== 'wordpress-authentication' &&
        !$route.meta.isHideSidebar
      "
    >
      <div
        class="sidebar-links text-sm font-medium py-2 flex flex-col justify-between h-full custom:h-sidebar"
        :class="[mode === 'dark' ? 'text-white-100' : 'text-gray-600']"
      >
        <div class="grid" :class="[isOpen ? 'gap-3' : 'gap-5']">
          <RouterLink
            :to="{ name: 'dashboard' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Dashboard' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Dashboard' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect width="7" height="9" x="3" y="3" rx="1" />
                  <rect width="7" height="5" x="14" y="3" rx="1" />
                  <rect width="7" height="9" x="14" y="12" rx="1" />
                  <rect width="7" height="5" x="3" y="16" rx="1" />
                </svg>
              </span>
              <div
                class="leading-none"
                :class="isOpen === true ? 'hidden' : ''"
              >
                Dashboard
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasKeywordPlannerPermission()"
            :to="{ name: 'KeywordPlanner' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Discovery' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Discovery' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isKeywordPlannerPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>START HERE Start with a base or seed keyword, and use
                  Advanced AI to discover the most lucrative and easiest
                  keywords and niches to go for! Plan Money Pages and Topical
                  clusters.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect width="7" height="7" x="3" y="3" rx="1" />
                  <rect width="7" height="7" x="3" y="14" rx="1" />
                  <path d="M14 4h7" />
                  <path d="M14 9h7" />
                  <path d="M14 15h7" />
                  <path d="M14 20h7" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Keyword Discovery</div>
                <div class="text-[10px] pt-2 leading-none">
                  Find Best Searches to Go For
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasTokenAnalysisPermission()"
            :to="{ name: 'serp-keyword-spy' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Keyword Spy' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Keyword Spy' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use Keyword Spy to Spy on Your competitors and Build Your Own AI models and get your best SEO keywords</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21 6H3" />
                  <path d="M10 12H3" />
                  <path d="M10 18H3" />
                  <circle cx="17" cy="15" r="3" />
                  <path d="m21 19-1.9-1.9" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Keyword Spy</div>
                <div class="text-[10px] pt-2 leading-none">
                  Find Boosting Keywords
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'editor' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Editor' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Article Builder' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>After Your Report is done, use Advanced AI to Generate / Optimize ALL of your SEO content: URLs, Titles, METAs, Headings, Images and Content with the Perfect Metrics!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="m18 5-2.414-2.414A2 2 0 0 0 14.172 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2"
                  />
                  <path
                    d="M21.378 12.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z"
                  />
                  <path d="M8 18h1" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Article Builder</div>
                <div class="text-[10px] pt-2 leading-none">
                  Automate Your OnPage SEO
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'reports' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Reports' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Reports' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isReportPages) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Check the Results on all of your Existing Keyword Metrics</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"
                  />
                  <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                  <path d="M8 18v-2" />
                  <path d="M12 18v-4" />
                  <path d="M16 18v-6" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Reports</div>
                <div class="text-[10px] pt-2 leading-none">
                  Investigate Boosting Keywords
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="featureFlag"
            :to="{ name: 'audit' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'OnPage Audit' : ''"
            v-tooltip="{
              content: `${isOpen ? 'OnPage Audit' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isAuditPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Check the Results on all of your Existing Keyword Metrics</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M12 7v14" />
                  <path d="M16 12h2" />
                  <path d="M16 8h2" />
                  <path
                    d="M3 18a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5a4 4 0 0 1 4 4 4 4 0 0 1 4-4h5a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-6a3 3 0 0 0-3 3 3 3 0 0 0-3-3z"
                  />
                  <path d="M6 12h2" />
                  <path d="M6 8h2" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">OnPage Audit</div>
                <div class="text-[10px] pt-2 leading-none">
                  AI SEO Audit Report
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            :to="{ name: 'projects' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Projects' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Projects' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive || isProjectsPage) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Handy way to organize Reports into Projects.</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="m6 14 1.45-2.9A2 2 0 0 1 9.24 10H20a2 2 0 0 1 1.94 2.5l-1.55 6a2 2 0 0 1-1.94 1.5H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H18a2 2 0 0 1 2 2v2"
                  />
                  <circle cx="14" cy="15" r="1" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Projects</div>
                <div class="text-[10px] pt-2 leading-none">
                  Group Your Reports
                </div>
              </div>
            </div>
          </RouterLink>
          <RouterLink
            v-if="userStore.hasZebraAIPermission()"
            :to="{ name: 'zebra-ai' }"
            v-slot="{ navigate, isActive, isExactActive }"
            :title="isOpen ? 'Beta Tools' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Beta Tools' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
            @click="redirect"
          >
            <div
              class="flex items-start py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
              :class="[
                (isActive || isExactActive) && 'active',
                isOpen && 'is-open justify-center',
                mode === 'dark'
                  ? 'text-white-100 hover:bg-dark-70'
                  : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
              ]"
              role="link"
              @click="navigate"
              v-tooltip="
                !isOpen
                  ? {
                      content: `<div style='max-width: 350px;'>Use AI to Analyze and Generate Helpful / Review Content, Workshop URL/Title/Meta/H1, AI Detection, AI Content Improver and Featured Snippet Generator!</div>`,
                      html: true,
                      placement: 'auto',
                      distance: 10,
                    }
                  : null
              "
            >
              <span class="flex-shrink-0 w-[22px] h-[22px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
                  <path d="M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                  <path d="M12 2v2" />
                  <path d="M12 22v-2" />
                  <path d="m17 20.66-1-1.73" />
                  <path d="M11 10.27 7 3.34" />
                  <path d="m20.66 17-1.73-1" />
                  <path d="m3.34 7 1.73 1" />
                  <path d="M14 12h8" />
                  <path d="M2 12h2" />
                  <path d="m20.66 7-1.73 1" />
                  <path d="m3.34 17 1.73-1" />
                  <path d="m17 3.34-1 1.73" />
                  <path d="m11 13.73-4 6.93" />
                </svg>
              </span>
              <div :class="isOpen === true ? 'hidden' : 'pt-[2px]'">
                <div class="leading-none">Beta Tools</div>
                <div class="text-[10px] pt-2 leading-none">
                  Check Out Our Beta AI Tools
                </div>
              </div>
            </div>
          </RouterLink>
          <div
            class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 cursor-pointer group"
            :class="[
              isOpen && 'is-open justify-center',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            @click="viewCreditsStatus = true"
            :title="isOpen ? 'View Plan/Credits' : ''"
            v-tooltip="{
              content: `${isOpen ? 'View Plan/Credits' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-[22px] h-[22px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect width="20" height="14" x="2" y="5" rx="2" />
                <line x1="2" x2="22" y1="10" y2="10" />
              </svg>
            </span>
            <span class="leading-none" :class="isOpen === true ? 'hidden' : ''"
              >View Plan / Credits</span
            >
          </div>
          <RouterLink
            :to="{
              name: 'seotraining',
            }"
            class="flex items-center py-2 px-3 relative rounded-md bg-opacity-5 gap-2.5 group"
            :class="[
              isOpen && 'is-open justify-center',
              mode === 'dark'
                ? 'text-white-100 hover:bg-dark-70'
                : 'hover:bg-primary-100 hover:text-black-100 hover:bg-opacity-5',
            ]"
            :title="isOpen ? 'Unlock SEO Training' : ''"
            v-tooltip="{
              content: `${isOpen ? 'Unlock SEO Training' : ''}`,
              placement: 'auto',
              distance: 10,
            }"
          >
            <span class="flex-shrink-0 w-[22px] h-[22px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1.75"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M4.9 19.1C1 15.2 1 8.8 4.9 4.9" />
                <path d="M7.8 16.2c-2.3-2.3-2.3-6.1 0-8.5" />
                <circle cx="12" cy="12" r="2" />
                <path d="M16.2 7.8c2.3 2.3 2.3 6.1 0 8.5" />
                <path d="M19.1 4.9C23 8.8 23 15.1 19.1 19" />
              </svg>
            </span>
            <div :class="isOpen === true ? 'hidden' : ''">
              <div class="leading-none">Unlock SEO Training</div>
            </div>
          </RouterLink>
        </div>
        <div
          class="flex items-center justify-center py-2 px-3 rounded-md gap-2.5 cursor-pointer"
          @click="toggleOpen"
          :title="isOpen ? 'Collapse' : ''"
          :class="[
            mode === 'dark'
              ? 'text-white-100 bg-dark-70'
              : 'bg-primary-100 hover:text-black-100 bg-opacity-5',
          ]"
        >
          <span class="w-[22px] h-[22px]">
            <svg
              v-if="isOpen"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="15 3 21 3 21 9" />
              <polyline points="9 21 3 21 3 15" />
              <line x1="21" x2="14" y1="3" y2="10" />
              <line x1="3" x2="10" y1="21" y2="14" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="4 14 10 14 10 20" />
              <polyline points="20 10 14 10 14 4" />
              <line x1="14" x2="21" y1="10" y2="3" />
              <line x1="3" x2="10" y1="21" y2="14" />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div
      class="transition flex-1 custom:ml-0"
      :class="[
        !isLoggedIn || $route.meta.isHideSidebar
          ? ''
          : isOpen === true
          ? 'ml-70'
          : 'ml-60',
      ]"
    >
      <RouterView :mode="mode" @toggle="toggle" :key="$route.path" />
    </div>
    <template v-if="isLoggedIn && $route.name !== 'signup'">
      <div
        v-if="viewCreditsStatus"
        class="fixed inset-0 z-[9999] flex items-center justify-center p-4 bg-opacity-40"
        :class="mode === 'dark' ? 'bg-white-100' : 'bg-black-100'"
        tabindex="-1"
      >
        <div
          class="max-w-3xl rounded-xl w-full mx-auto"
          :class="mode === 'dark' ? 'bg-dark-70' : 'bg-white-100'"
        >
          <div
            class="flex items-center justify-between px-4 py-2 border-b"
            :class="mode === 'dark' ? 'border-dark-50' : 'border-border-100'"
          >
            <h5 class="text-lg font-semibold leading-none">Credits</h5>
            <svg
              @click="viewCreditsStatus = false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="cursor-pointer w-9 p-1 rounded-full"
              :class="
                mode === 'dark' ? 'hover:bg-dark-50' : 'hover:bg-gray-100'
              "
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M368 368L144 144M368 144L144 368"
              ></path>
            </svg>
          </div>
          <div class="bg-opacity-30 max-h-modal overflow-y-auto p-4">
            <div class="grid gap-4">
              <div class="flex items-center justify-between">
                <p class="font-semibold">
                  Your Monthly Usage Stats and Plan Type
                </p>
                <div
                  class="button primary-button small-button"
                  @click="addMoreCredits"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="w-5 h-5 mr-1 -ml-1"
                    fill="currentColor"
                  >
                    <path
                      d="M2.5 6.75a2.75 2.75 0 0 1 2.75-2.75h9.5a2.75 2.75 0 0 1 2.75 2.75v1.25a.75.75 0 0 1-1.5 0v-1.25c0-.69-.56-1.25-1.25-1.25h-9.5c-.69 0-1.25.56-1.25 1.25v.5h8a1 1 0 1 1 0 2h-8v4c0 .69.56 1.25 1.25 1.25h4.052a.75.75 0 0 1 0 1.5h-4.052a2.75 2.75 0 0 1-2.75-2.75v-6.5Z"
                    />
                    <path
                      d="M5.75 11.5a.75.75 0 0 0 0 1.5h2a.75.75 0 0 0 0-1.5h-2Z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M13 12.25a1 1 0 0 0-1 1v2.75a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-2.75a1 1 0 0 0-1-1v-.75a2 2 0 1 0-4 0v.75Zm2.5 0v-.75a.5.5 0 0 0-1 0v.75h1Z"
                    />
                  </svg>
                  Add More Credits
                </div>
              </div>
              <ChartComponent />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed } from "vue";
import { productFruits } from "product-fruits";
import ApiRequest from "./libs/ApiRequest";
import helper from "./libs/helper";
import ChartComponent from "./components/ChartComponent.vue";
import { useUserStore } from "./stores/user";
export default {
  setup() {
    const userStore = useUserStore();
    return {
      isLoggedIn: computed(() => userStore.isLoggedIn),
      accessToken: computed(() => userStore.accessToken),
      isLoading: computed(() => userStore.isloading),
      loaderLable: computed(() => userStore.loaderLable),
      isFullPage: computed(() => userStore.isFullPage),
      user: computed(() => userStore.user),
      userStore,
    };
  },
  components: {
    ChartComponent,
  },
  data() {
    return {
      mode: localStorage.getItem("mode") || "light",
      accessToken: "",
      collapseSidebar: localStorage.getItem("menu-sidebar") || "open",
      loader: null,
      isOpen: false,
      viewCreditsStatus: false,
      isReportPages: false,
      isKeywordPlannerPage: false,
      isProjectsPage: false,
      isCollapsed: false,
      isAuditPage: false,
    };
  },
  methods: {
    addMoreCredits() {
      this.viewCreditsStatus = false;
      this.$router.push({ name: "account-subscription" });
    },
    toggleOpen() {
      if (this.isOpen == false) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },
    toggle() {
      if (this.mode === "dark") {
        this.mode = "light";
      } else {
        this.mode = "dark";
      }
      localStorage.setItem("mode", this.mode);
    },
    onCancelLoader() {
      this.userStore.setIsLoading(!1);
    },
    async getCurrentUser() {
      try {
        const URL = `/api/auth/current-user`;
        const res = await ApiRequest().get(URL);
        if (res.status !== 200) {
          throw new Error("Something went wrong!");
        }
        this.userStore.setUser(res.data);
      } catch (error) {
        const extractedError = helper.extractAlert(error, "app");
        this.$toast.error(extractedError.alert.message);
      }
    },
    toggleSidebar() {
      if (this.collapseSidebar === "open") {
        this.collapseSidebar = "close";
      } else {
        this.collapseSidebar = "open";
      }
      localStorage.setItem("menu-sidebar", this.collapseSidebar);
    },
    handleIsLoading() {
      let options = {
        canCancel: false,
        onCancel: this.onCancelLoader,
        loader: "spinner",
        height: 45,
      };
      if (this.isFullPage) {
        options["container"] = this.isFullPage;
      }
      if (this.isLoading) {
        this.loader = this.$loading.show(options, {
          after: this.loaderLable,
        });
      } else if (this.loader) {
        this.loader.hide();
      }
    },
    initProductFruits() {
      if (this.userStore.user && this.userStore.user.email) {
        const { email, first_name, last_name, phone_number } =
          this.userStore.user;
        if (!window.productFruits) {
          productFruits.init(
            "nG7StDYhiGiO5TOh",
            "en",
            {
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            },
            {
              disableLocationChangeDetection: true,
            }
          );
        } else {
          if (window.productFruits.identifyUser) {
            window.productFruits.identifyUser({
              username: email,
              email,
              first_name,
              last_name,
              phone_number,
              props: {
                uuid: (this.$route.params && this.$route.params.uuid) || "",
                routeName: this.$route.name || "",
              },
            });
          }
        }
      }
    },
    redirect() {
      this.isCollapsed = false;
      window.dispatchEvent(
        new CustomEvent("isCollapsed", {
          detail: {
            isCollapsed: this.isCollapsed,
          },
        })
      );
    },
  },
  mounted() {
    this.initProductFruits();
    window.addEventListener("collapseSidebar", (event) => {
      this.isCollapsed = event.detail.isCollapsed;
    });
  },
  watch: {
    $route(to, from) {
      const reloadUserOnRoutes = [
        "subscription-confirmed",
        "subscription-upgrade",
        "subscription-cancelled",
        "account-subscription",
      ];
      if (reloadUserOnRoutes.includes(to.name)) {
        this.getCurrentUser();
      }
      if (["editor", "editor-public"].includes(to.name)) {
        this.collapseSidebar = "close";
      }
      if (to.name === "reports" || to.name === "serp-report-ta") {
        this.isReportPages = true;
      } else {
        this.isReportPages = false;
      }
      if (to.name.includes("KeywordPlanner")) {
        this.isKeywordPlannerPage = true;
      } else {
        this.isKeywordPlannerPage = false;
      }
      if (to.name === "projects" || to.name === "edit-project") {
        this.isProjectsPage = true;
      } else {
        this.isProjectsPage = false;
      }
      if (to.name === "audit") {
        this.isAuditPage = true;
      } else {
        this.isAuditPage = false;
      }
      const steps = [
        "/keyword-discovery",
        "/serp/keyword-spy",
        "/serp/reports",
        "/article-builder",
      ];
      const currentIndex = steps.indexOf(to.path);
      if (currentIndex > -1) {
        this.userStore.setCurrentDashboardStep(currentIndex + 1);
      }
    },
    user(val) {
      if (val.email) {
        this.initProductFruits();
      }
    },
    isLoading() {
      this.handleIsLoading();
    },
  },
  computed: {
    featureFlag() {
      return import.meta.env.VITE_API_BASE_URL.includes("staging");
    },
  },
};
</script>
